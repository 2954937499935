import axios from 'axios';
import { toast } from 'react-toastify';
import { endpoints } from 'src/api/endpoints';
import { post } from 'src/api/requests';
import Iconify from 'src/components/Iconify';
import { IconWrapperStyleReusable } from 'src/pages/Event/SideBar/SideBar';

export const checkIfPackageExpired = (expiry, today = new Date(), ignoreGrace) => {
  const difference = new Date(expiry).getTime() - today.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  // console.log(TotalDays);
  if (ignoreGrace) {
    if (TotalDays > 0) {
      return {
        willExpireBefore: true,
        willExpireByDays: TotalDays,
      };
    } else {
      return {
        willExpireBefore: false,
      };
    }
  } else {
    if (TotalDays < -10) {
      return {
        isExpired: true,
        daysLeft: TotalDays,
      };
    } else {
      return {
        isExpired: false,
        daysLeft: TotalDays,
      };
    }
  }
};
export const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  currencyDisplay: 'symbol',
});

export const specialChars = /[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;

export const containsSpecialChars = (str) => {
  return specialChars.test(str);
};

export const cleanUpText = (text = 'undefined', returnEmpty = false) =>
  !text
    ? returnEmpty
      ? ''
      : '-'
    : decodeURI(text.replaceAll('undefined', returnEmpty ? '' : '-').replaceAll('null', returnEmpty ? '' : '-'));

export const levelToText = (level = 'a') => {
  if (level === 'a') {
    return 'Level 1';
  } else if (level === 'b') {
    return 'Level 2';
  } else if (level === 'c') {
    return 'Level 3';
  }
};

export const isMultipleOf = (mainNumber, subjectNumber) => {
  if ((subjectNumber / mainNumber) % 1 === 0) {
    return true;
  } else {
    return false;
  }
};
export const loopAndBreak = (array) => {
  const newArr = [];
  for (let i = 0; i <= array.length; i++) {
    if (isMultipleOf(2, i)) {
      const thisItem = array[i];
      const prevItem = array[i - 1];
      const arr = {};
      if (thisItem) {
        arr.a = thisItem;
      }
      if (prevItem) {
        arr.b = prevItem;
      }
      newArr.push(arr);
    }
  }
  return newArr;
};

export const IconIfTrue = (value = 'false', icon) =>
  JSON.parse(value) ? (
    <IconWrapperStyleReusable>
      <Iconify width={19} height={19} icon={icon} />
    </IconWrapperStyleReusable>
  ) : (
    '-'
  );

export const sqlDateToReadable = (val, valuesToReturn = 'all') => {
  const date = new Date(val).toDateString();
  const time = new Date(val).toTimeString();
  return valuesToReturn === 'all'
    ? date + ', ' + time
    : valuesToReturn === 'date'
    ? date
    : valuesToReturn === 'time' && time;
};

export const removeAllPTags = (text) =>
  text
    .replace(/<p>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/<\/p>/g, '')
    .replace(/<[^<>]+>/g, ' ');

export const underScoreToReadable = (text = '', returnCapitalized) => {
  if (typeof text !== 'string' || text.length === 0) {
    return '';
  }
  const result = text.replace(/_/g, ' ');
  if (returnCapitalized) {
    return result
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const readableToUnderscore = (text, returnAsLowercase = false) => {
  if (returnAsLowercase) {
    return text?.replaceAll(' ', '_')?.replace(text[0], text[0]?.toLowerCase()).toLowerCase();
  } else {
    return text?.replaceAll(' ', '_')?.replace(text[0], text[0]?.toLowerCase());
  }
};

export const uploadFileToCloudinary = async (formdata, successFn, errorFn) => {
  formdata.append('upload_preset', 'voezchrp');
  await axios
    .post('https://api.cloudinary.com/v1_1/arsh-events/auto/upload', formdata)
    .then((response) => {
      successFn(response?.data?.secure_url);
    })
    .catch((error) => {
      toast.error('Your files failed to upload ');
      toast.error(error?.message);
      errorFn(error?.message);
      return { type: 'failure' };
    });
};

export const adminTrackActivity = async (action_title, action_body, user, event, reload) => {
  const activity = { action_title, action_body, taken_by: user?.name + ' role_is ' + user?.role, event };
  await post(
    endpoints?.logadminactivity,
    activity,
    (data) => {
      toast.success(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (reload) {
        setTimeout(() => {
          window.location = '';
        }, 1000);
      }
    },
    (error) => {
      toast.error('Could not log activity.');
      console.log(error);
    },
    false
  );
};

export function download_table_as_csv(table_id, name = 'ARSH GENERATED FILE', separator = ',') {
  // Select rows from table_id
  var rows = document.querySelectorAll('table#' + table_id + ' tr');
  // Construct csv
  var csv = [];
  //looping through the table
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll('td, th');
    //looping through the tr
    for (var j = 0; j < cols.length; j++) {
      // removing space from the data
      var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ');
      // removing double qoute from the data
      data = data.replace(/"/g, `""`);
      // Push escaped string
      row.push(`"` + data + `"`);
    }
    csv.push(row.join(separator));
  }
  var csv_string = csv.join('\n');
  // Download it
  var filename = name + '.csv';
  var link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const deleteStuff = async (
  id,
  data,
  confirmMessage,
  successReturn = () => console.log('success'),
  errorReturn = () => console.log('error')
) => {
  console.log(data);
  if (window.confirm(confirmMessage || 'Are you sure you want to delete? This can not be undone.') === true) {
    await post(
      endpoints?.delete,
      data,
      (data) => {
        toast.success(data?.message);
        // if (reload) {
        //   setTimeout(() => {
        //     window.location = '';
        //   }, 2000);
        // }
        successReturn();
      },
      (error) => {
        toast.error('Could not delete data.');
        console.log(error);
        errorReturn();
      },
      false
    );
  }
};

export const checkAddOn = (value, criteria) => {
  if (value?.includes(criteria)) {
    return true;
  } else {
    return false;
  }
};

export function isDateObsolete(inputDate) {
  const currentDate = new Date();
  const targetDate = new Date(inputDate);

  if (targetDate > currentDate) {
    return false;
  } else if (targetDate < currentDate) {
    return true;
  } else {
    return false;
  }
}
