// routes
// components
import Iconify from 'src/components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const APP_ICONS = {
  exhibitors: getIcon('ic_invoice'),
  add_exhibitor: getIcon('ic_user'),
  ic_chat: getIcon('ic_chat'),
  ic_calendar: getIcon('ic_calendar'),
  ic_analytics: getIcon('ic_analytics'),
  ic_booking: getIcon('ic_booking'),
  ic_banking: getIcon('ic_banking'),
  dashboard: getIcon('ic_dashboard'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
};

export const IconifyIcon = (icon) => <Iconify icon={icon} width={24} height={24} />;

const navConfig = [
  {
    subheader: 'Dashboard',
    accessLevels: [1, 2, 3, 4, 5],
    package: 'all',
    items: [
      {
        title: 'Exhibitor Dashboard',
        path: '/exhibitor-dashboard/home',
        icon: IconifyIcon('dashicons:dashboard'),
        package: 'all',
        accessLevels: [1, 2, 3, 4, 5],
      },
    ],
  },

  {
    subheader: 'Exhibitor Details',
    accessLevels: [1, 2, 3],
    package: 'exhibitions',
    items: [
      {
        package: 'exhibitions',
        title: 'Edit Exhibitor Details',
        accessLevels: [1],
        path: '/exhibitor-dashboard/edit-details',
        icon: IconifyIcon('fluent:desktop-edit-20-regular'),
      },
      {
        package: 'exhibitions',
        title: '3D Booth Editor',
        accessLevels: [1],
        path: '/exhibitor-dashboard/edit-booth',
        icon: IconifyIcon('bxs:cube'),
      },
      // { title: 'Music', path: '/exhibitor-dashboard/music', icon: APP_ICONS.add_exhibitor },
      //This would probably be the same as event settings since its one db.. idk, maybe not.
      // { title: 'Event controls', path: '/exhibitor-dashboard/event-controls', icon: APP_ICONS.add_exhibitor },
    ],
  },

  {
    subheader: 'Data & Analytics',
    package: 'exhibitions',
    accessLevels: [1, 2, 3],
    items: [
      {
        title: 'Exhibitor Analytics',
        package: 'exhibitions',
        path: '/exhibitor-dashboard/analytics',
        icon: IconifyIcon('ant-design:area-chart-outlined'),
        accessLevels: [1, 2, 3],
        children: [
          {
            title: 'Virtual Booth Visits',
            path: '/exhibitor-dashboard/analytics/virtual-visits',
            icon: APP_ICONS.add_exhibitor,
            package: 'exhibitions',
            accessLevels: [1, 2, 3],
          },
          {
            title: 'Physical Booth Visits',
            path: '/exhibitor-dashboard/analytics/physical-visits',
            icon: APP_ICONS.add_exhibitor,
            package: 'exhibitions',
            accessLevels: [1, 2, 3],
          },
          {
            title: 'Booth Ratings',
            path: '/exhibitor-dashboard/analytics/ratings',
            icon: APP_ICONS.add_exhibitor,
            package: 'exhibitions',
            accessLevels: [1, 2, 3],
          },
          {
            title: 'Booth Votes',
            path: '/exhibitor-dashboard/analytics/votes',
            package: 'exhibitions',
            icon: APP_ICONS.add_exhibitor,
            accessLevels: [1, 2, 3],
          },
        ],
      },
    ],
  },

  {
    subheader: 'Live Voting',
    accessLevels: [1, 2],
    package: 'exhibitions',
    items: [
      {
        title: 'Live Voting',
        path: '/exhibitor-dashboard/live-voting',
        package: 'exhibitions',
        icon: IconifyIcon('mdi:crown-circle'),
        accessLevels: [1, 2],
      },
    ],
  },

  {
    subheader: 'Video Meeting',
    package: 'exhibitions',
    accessLevels: [1, 2],
    items: [
      {
        title: 'Add Video Meeting',
        path: '/exhibitor-dashboard/zoom',
        icon: IconifyIcon('dashicons:playlist-video'),
        package: 'exhibitions',
        accessLevels: [1, 2],
      },
    ],
  },

  {
    subheader: 'Exhibitor Live Chat',
    package: 'exhibitions',
    accessLevels: [1, 2, 4],
    items: [
      {
        title: 'Live Chat',
        package: 'exhibitions',
        path: '/exhibitor-dashboard/live-chat',
        icon: IconifyIcon('bi:chat-right-dots-fill'),
        accessLevels: [1, 2, 4],
      },
    ],
  },
];

export default navConfig;
